import { Directive, ElementRef, OnInit } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { DomController } from '@ionic/angular';


@Directive({
  selector: 'ion-textarea[autosize]'
})
export class TextareaAutosizeDirective implements OnInit {

  private inputObservable: Observable<any>;

  constructor(private el: ElementRef, private domCtrl: DomController) { }

  ngOnInit() {
    const mutationObserver = new MutationObserver(() => {
      this.el.nativeElement.style.height = `${this.el.nativeElement.lastChild.scrollHeight}px`;

      this.inputObservable = fromEvent(this.el.nativeElement.querySelector('textarea'), 'input');
      this.inputObservable.subscribe((inputEvent: any) => {

        const textAreaNative = inputEvent.target;
        this.domCtrl.write(() => {
          this.el.nativeElement.style.height = 'auto';
          textAreaNative.style.height = 'auto';
          textAreaNative.style.height = `${textAreaNative.scrollHeight}px`;
        });

      });
    });
    mutationObserver.observe(this.el.nativeElement, {
        childList: true,
    });
  }
}