import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-field',
  templateUrl: './add-field.page.html',
  styleUrls: ['./add-field.page.scss'],
})
export class AddFieldPage implements OnInit {

  field = {name:'', content:'', type:'text', label:''}
  
  constructor(private modalController: ModalController ) { }

  ngOnInit() {
  }

  async returnData(){
    this.modalController.dismiss(this.field)
  }
  async dismiss(){
    this.modalController.dismiss()
  }

}
