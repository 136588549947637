import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AskComponent } from '../../ask/ask.component'
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';


import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  userIsLoggedIn
  userDetails
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Articles',
      url: '/articles',
      icon: 'newspaper'
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'people-circle'
    }
  ];
  constructor( private authService: AuthService, private afAuth: AngularFireAuth, public router: Router, private fireStore: AngularFirestore) { 
    this.afAuth.authState.subscribe(
      (user) => {
        if (user) {
          this.userIsLoggedIn = true
          
          this.fireStore.doc('users/' + user.uid).valueChanges()
          .subscribe(
            (res)=>{
              this.userDetails = res
              // console.log('this.userIsLoggedIn ', user)
              // console.log('this.userDetails ', res)
            }
          )
        }
        else {
          this.userIsLoggedIn = false;
        }
      }
    );
    
  }

  ngOnInit() {
    
  }

  logOut(){
    this.afAuth.signOut()
  }

}
