import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {IonSlides} from '@ionic/angular';
import { File } from '@ionic-native/file';
import { FileOpener } from '@ionic-native/file-opener';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import jsPDF from 'jspdf';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { AddFieldPage } from './add-field/add-field.page'
import { AddSectionPage } from './add-section/add-section.page'
import { AddCountryPage } from './add-country/add-country.page'
import { ShowFieldHistoryPage } from './show-field-history/show-field-history.page'
import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { DisplayModalComponent } from '../utils/display-modal/display-modal.component'

import { AuthService } from '../services/auth.service'
import { debug } from 'util';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit {
  @ViewChild('#pdfTable', {read: true, static: false}) pdfTable: ElementRef;
  @ViewChild(IonSlides, {static: true}) slides: IonSlides;
  // @ViewChild(IonReorderGroup, { read: true, static: false }) reorderGroup: IonReorderGroup;

  countries
  countryNames =[]
  countryNamesEmpty =[{id: 1, itemName: 'You need to be logged in to use this feature'}]
  availableNewsCountryNames =[{id: 99, itemName: 'EUROPE', originalName: 'Europe', expanded : true}]
  newsCountryNames =[]
  pdfObj = null
  fields
  news
  user
  userDetails
  userLoggedIn
  selectedCountries =[]
  selectedNewsCountries = []
  multiselectedCountries =[]
  selectedSection
  selectedField
  fieldCounter
  modifiedFields =[]
  showContent ={}
  currentUser
  appendices
  dropdownSettings = { 
    singleSelection: false, 
    text: 'Select Countries',
    selectAllText:'Select All',
    unSelectAllText:'UnSelect All',
    enableSearchFilter: true,
    classes:"multiselect"
  };
  slideOpts = {
    slidesPerView: 3,
    autoHeight: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 2,
        spaceBetween: 40
      }
    }
  }

  constructor(private _firebaseAuth: AngularFireAuth, private firestore: AngularFirestore, public modalController: ModalController, private authService: AuthService) {
    this.authService.isLoggedIn().subscribe(
      (res)=>{
        this.userLoggedIn = res
      }
    )
    this.getAppendices()
   }

  ngOnInit() {
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.user = res;
            this.firestore.doc('users/' + this.user.uid).valueChanges().subscribe(
              (r)=>{
                this.userDetails = r
                console.log('[Country Report] CurrentUser ', this.userDetails)
                this.userDetails.countriesAccess.forEach(
                  (country, i)=>{
                    this.userDetails.countriesAccess[i] = country.toLowerCase()
                  }
                )
                this.getCountries()
              }
            )
          }
          else {
            this.user = null;
          }
        }
    )

    this.getNews()
  }

  getCountries(){
    this.firestore.collection('countries').snapshotChanges()
    .subscribe(
      (res)=>{
        res.sort((a, b) => a.payload.doc['id'].localeCompare(b.payload.doc['id']))
        let i = 0
        this.countries = {}
        res.forEach(
          r=>{
            const countryId = r.payload.doc['id']
            if ( this.userDetails.countriesAccess.includes(countryId.toLowerCase()) ) {
              let country = {name: countryId}
              // Get SECTIONS
              this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
              .subscribe(
                (sec)=>{
                  if(sec){
                    let countrySections = {}
                    countrySections['names']=[]
                    countrySections['details']={}
                    // console.log('Got section ', sec)
                    sec.forEach(
                      (section)=>{
                        const sectionId = section.payload.doc['id']
                        // countrySections['names'].push(sectionId) // DEZACTIVAT TEMPORAR impreuna cu harcodarea de mai jos
                        // GET SECTION FIELDS
                        this.firestore.collection('countries/' + countryId + '/sections/' + sectionId + '/fields').valueChanges()
                        .subscribe(
                          (fields)=>{
                            // console.log('Got Fields ', sec)
                            countrySections['details'][sectionId] = fields.sort(function (a, b) {
                              // console.log('Sorting', a)
                              if (a['position'] < b['position']) {
                                  return -1;
                              }
                              if (b['position'] > a['position']) {
                                  return 1;
                              }
                              return 0;
                            })
                            countrySections['details'][sectionId]  = this.formatContent( countrySections['details'][sectionId] )
                          }
                        )
                      }
                    )
                    // momentan le hardcodam, ca sa avem ordinea buna
                    countrySections['names'] = [
                      "TAX AUTHORITY AND LEGISLATION",
                      "LEGISLATIVE DETAILS",
                      "MAIN BENEFIT TEST (MBT)",
                      "LEGAL PROFESSIONAL PRIVILEGE (LPP)",
                      "FORM OF REPORTING AND LANGUAGE",
                      "TIMELINES",
                      "PENALTIES",
                      "ADDITIONAL GUIDANCE",
                    ]

                    country['sections'] = countrySections 
                    
                  }
                }
              )
              i++
              this.countryNames.push({id: i, itemName: this.titleCase(countryId), originalName: countryId, expanded : true})
              this.availableNewsCountryNames.push({id: i, itemName: this.titleCase(countryId), originalName: countryId, expanded : true})
              this.countries[countryId] = country
            }
            // else{
            //   console.log(this.userDetails.countriesAccess, 'does not include ' + countryId)
            // }
          
          }
        )
      }
    )

    
    console.log('Countries ', this.countries)
  }
  
  getAppendices(){
    this.firestore.collection('appendices/' ).valueChanges()
      .subscribe(
        (res)=>{
          this.appendices = res.sort((a, b) => {
            if (a['order'] > b['order']) {
              return 1;
          }
          if (b['order'] > a['order']) {
              return -1;
          }
          return 0;
          })
        }
      )
  }

  getCountrySections(countryId){
    return this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
      .subscribe()

    
  }

  async exportPdf(country){
    var docDefinition = {
      content: [
        { image: await this.getBase64ImageFromURL("/assets/flags/" + country.toLowerCase() + '.png'), width: 150, alignment: 'center'},
        { text: country.toUpperCase(), style: 'header', alignment: 'center' },  
        { table: {
            // headers are automatically repeated if the table spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [ '*', 'auto', 100, '*' ],
    
            body: [
              [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ],
              [ { text: 'Bold value', bold: true }, 'Val 2', 'Val 3', 'Val 4' ]
            ]
          }    
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          fillColor: '#abc612'
        },
        subheader: {
          fontSize: 14,
          bold: true,
          margin: [0, 15, 0, 0]
        },
        leftColumnn: {
          bold: true,
          alignment: 'center',
          width: '30%',
        },
        rightColumnn: {
          alignment: 'left',
          width: '50%',
        }
      }
    }
        let pdfRow = Array()
        pdfRow.push('ssss')
    docDefinition.content[2].table.body.push(pdfRow)
    // this.countries[country]['sections']['names'].forEach(
    //   (sectionName)=>{
    //     let pdfRow = [ 'name', 'name2']
    //     console.log('pdfRow ', pdfRow)
    //     docDefinition.content[2].table.body.push(pdfRow)
    //     this.countries[country]['sections']['details'][sectionName].forEach(
    //       (field) => {
    //         // console.log(docDefinition.content[2])
    //         if(!field.name) { field.name =' '}
    //         if(!field.name) { field.name =' '}
    //         // docDefinition.content[2].table.body.push([{ 'text': field.name}])
    //         // docDefinition.content[2].table.body.push({ 'text': field.content})
    //     });
    //   })
      
    // <div *ngFor="let sectionName of countries[country]['sections']['names']">
    //               <h3>{{sectionName}}</h3>
    //               <ion-row *ngFor="let field of countries[country]['sections']['details'][sectionName]">
    //                 <ion-col size="3">
    //                   {{field.name}}
    //                 </ion-col>
    //                 <ion-col size="9">
    //                   <a *ngIf="field.type === 'link'" [href]="field.content" target="_blank">{{field.label}}</a>
    //                   <div *ngIf="field.type !== 'link'" [innerHTML]="field.content"></div>
    //                 </ion-col>
    //               </ion-row>
    //             </div>


    this.pdfObj = pdfMake.createPdf(docDefinition);
    this.pdfObj.download('DAC6_' + this.titleCase(country));
  }

  // downloadPdf() {
  //   if (this.plt.is('cordova')) {
  //     this.pdfObj.getBuffer((buffer) => {
  //       var blob = new Blob([buffer], { type: 'application/pdf' });

  //       // Save the PDF to the data Directory of our App
  //       this.file.writeFile(this.file.dataDirectory, 'myletter.pdf', blob, { replace: true }).then(fileEntry => {
  //         // Open the PDf with the correct OS tools
  //         this.fileOpener.open(this.file.dataDirectory + 'myletter.pdf', 'application/pdf');
  //       })
  //     });
  //   } else {
  //     // On a browser simply use download!
  //     this.pdfObj.download();
  //   }
  // }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  selectCountries(event){
    if (!this.userLoggedIn) {
      alert('You need to be logged in in order to use this feature!')
      return
    }
    console.log('[Articles] [selectCountries] $event ', event)
    this.selectedCountries = []
    if (event !== 'onDeSelectAll') {
      this.multiselectedCountries.forEach(
        (res)=>{
          this.selectedCountries.push(res.originalName)
          if(this.showContent[res.originalName] !== false){
            this.showContent[res.originalName] = true
          }
        }
      )  
    }
    else{
      console.log('[Articles] [selectCountries] $onDeSelectAll ', this.selectedCountries)
    }
  }
  selectNewsCountries($event){
    console.log('selectNewsCountries', $event)
    this.selectedNewsCountries=[]
    this.newsCountryNames.forEach(country => {
      this.selectedNewsCountries.push(country.originalName)
    });
  }

  toggleContent(countryName){
    // this.selectedCountries.forEach(
    //   (country, i )=>{
    //     if (country.name === countryName) {
    //       // console.log(i+ ' Name ' + country.name + ' === ' , this.selectedCountries[i] )
    //       // this.selectedCountries[i].expanded = false
    //       this.showContent[countryName] = this.showContent[countryName]  ? false : true;
    //     }
    //     else{
    //       console.log(' Name ' + country.name + ' !=== ' + countryName )
    //     }
    //   }
    // )
  }

  getNews(){
    this.firestore.collection('news/').valueChanges()
      .subscribe(
        (res)=>{
          this.news = res.sort(function (a, b) {
            if (a['date_time'] > b['date_time']) {
                return -1;
            }
            if (b['date_time'] > a['date_time']) {
                return 1;
            }
            return 0;
          })
        }
      )

    
  }

   async showNews(article){
    console.log('[showNews]', article)
    const d = article.date_time.toDate();
    const articleDate =d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    console.log('articleDate', articleDate)

    let articleText = '<div class="news-header"><div class="news-flag"><img src="/assets/flags/' 
                      + article.country.toLowerCase() + '.png" class="flag-2"></div><div class="news-title">'
                      + article.country.toUpperCase() + '<div class="news-date">' + articleDate + '</div></div></div>'
                      + '<b>' + article.title + '</b>'
    articleText += article.longText
    const modal = await this.modalController.create({
      component: DisplayModalComponent,
      componentProps: { 
        text: articleText
      }
    });
    return await modal.present();
  }

  slidePrev(slides) {
    slides.slidePrev();
  }
  slideNext(slides) {
    slides.slideNext();
  }

  // UTILS

  titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

  formatContent(fields){
    fields.forEach(field => {
        if(field.content && field.type !== 'link'){
          let text = field.content;
          text = text.toString().replace(/	/g, '<br> • ')
          text = this.createTextLinks_(text)
          field.content = text 
        }
    });
    return fields
  }

 createTextLinks_(text) {
  return (text || "").replace(
    /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
    function(match, space, url){
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return space + '<a href="' + hyperlink + '" target="_blank">' + url + '</a>';
      }
    );
  };


}
