import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent,  HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.scss'],
})
export class BookDemoComponent implements OnInit {

  formData={
    name: '',
    email: '',
    phone: '',
    body: '',
    subject: 'Dac6Guide - Book a demo',
    preferEmail: false,
    preferPhone: false,
    preferAll: false
  }

  constructor( private modalController: ModalController, private http: HttpClient, private router: Router ) { }

  ngOnInit() {}

  dismissModal(){
    this.modalController.dismiss()
  }

  sendEmail(){
    
    console.log('Data: ', this.formData)

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if(!this.formData.name || !this.formData.email){
      alert('Please fill all the fields before sending!')
    }
    else{
      this.formData.body = '<br><br><br>DAC6 reporting tool DEMO request through dac6guide.eu<br>'
      this.formData.body += 'From:' + this.formData.name + '<br>'
      this.formData.body += 'Email:' + this.formData.email + '<br>'
      this.formData.body += 'Phone:' + this.formData.phone + '<br>'
      if (this.formData.preferEmail ) {
        this.formData.body += 'Prefer to be contacted by email<br>'
      }
      if(this.formData.preferPhone){
        this.formData.body += 'Prefer to be contacted by phone<br>'
      }
      if(this.formData.preferAll){
        this.formData.body += 'Prefer to be contacted by either phone or mail<br>'
      }
      
      if(!this.formData.name || !this.formData.email){
        alert('Please fill all the fields before sending!')
      }
      else{
        this.modalController.dismiss()
        this.http.post(
            'https://sendemail.dac6guide.eu/sendmail.php', this.formData, httpOptions
        )
        .subscribe(
          res => {
            console.log('Mail Result:' , res)
            this.formData={name: '', email: '', phone: '', body: '', preferEmail: false,preferPhone: false, preferAll: false, subject: 'Dac6Guide - Ask an expert'
            }
            this.goToConfirm()
            // alert('Thank you for your message. We will get back to you in a very short time!')
            // this.showAlert('All good', 'An email was sent to the user with instructions to create their account')
          },
          err => {
            alert('Your message could not be sent! Please try again')
            console.log('Mail Error:' , err)
            // this.showAlert('Error', 'User data wasnt completly saved! Please contact your system administrator!')
          }
        );
      }
    }
   
  }

  goToConfirm(){
    this.modalController.dismiss()
    this.router.navigate(['demo-request-sent']);
  }

}
