import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user
  public userDetails

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private fireStore: AngularFirestore) { 
        this.getUserData()
  }

  getUserData(){
    return new Observable(
      (observer) => {
        this._firebaseAuth.authState.subscribe(
          (res) => {
              if (res) {
                this.user = res;
                console.log('[Auth Service] User details', this.userDetails);
                this.fireStore.doc('users/' + this.user.uid).valueChanges().subscribe(
                  (r)=>{
                    this.userDetails = r
                    observer.next(r)
                    if(r['active'] && r['active'] === false){
                      alert('Your account is NOT ACTIVE yet. Please contact us at contact@dac6guide.eu')
                      this._firebaseAuth.signOut()
                    }
                  }
                )
              }
              else {
                this.user = null;
              }
            }
        )
    });
    
  }

  isLoggedIn(): Observable<boolean> {
    return new Observable(
      (observer) => {
        this._firebaseAuth.authState.subscribe(
          (res) => {
            if(res){
              observer.next(true);
            }
            else{
              observer.next(false);
            }
            
          },
      (err) => observer.error(err));
    });

}

  logout() {
    this._firebaseAuth.signOut()
      .then((res) => this.router.navigate(['/login']));
  }

  signInRegular(email, password) {
      const credential = firebase.auth.EmailAuthProvider.credential( email, password );
  return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  signInWithTwitter() {
    return this._firebaseAuth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    )
  }

  signInWithFacebook() {
      return this._firebaseAuth.signInWithPopup(
        new firebase.auth.FacebookAuthProvider()
      )
    }
    
  signInWithGoogle() {
      return this._firebaseAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
    }



}

